<template>
  <h1 class="text-2xl font-semibold text-gray-900">
    Werkdagen
  </h1>
  <div class="py-4">
    <UISelectChauffeur
      v-model="user_id"
      label
      active
      nullable
      nullable-label="Vaste vrije dagen tonen"
      nullable-value="-1"
    />

    <UITableResponsive
      v-if="werkdagen.length > 0 && user_id == -1"
      class="mt-4 scale-90"
      :items="['VM', 'NM']"
      :headers="dagen"
    >
      <template v-for="dag in dagen" :key="dag" #[`item-${dag}`]="{ item: dagDeel }">
        <div class="px-4 flex flex-col h-full gap-2">
          <h2>{{ dagDeel === 'VM' ? 'Voormiddag' : 'Namiddag' }}</h2>
          <button
            v-for="chauffeur in actieveChauffeursNietWerkend(dag, dagDeel)"
            :key="chauffeur.fullname"
            class="card hover:underline"
            @click="user_id = chauffeur.user_id"
          >
            {{ chauffeur.fullname }}
          </button>
        </div>
      </template>
    </UITableResponsive>

    <UITableResponsive
      v-if="user_id > 0"
      class="mt-4"
      :items="[werkdagen]"
      :headers="dagen"
      :prefix-headers="['Selectie']"
      :suffix-headers="['Aanvragen_via', 'Acties']"
    >
      <template #colgroup>
        <colgroup>
          <col class="w-0">
          <col class="w-0">
          <col class="w-0">
          <col class="w-0">
          <col class="w-0">
          <col class="w-0">
          <col class="w-0">
          <col class="w-0">
          <col class="w-0">
          <col class="w-auto">
        </colgroup>
      </template>

      <template v-for="dag in dagen" :key="dag" #[`item-${dag}`]="{ item }">
        <div class="flex flex-col gap-2 card items-center justify-center px-4">
          <span v-for="dagDeel in ['VM', 'NM']" :key="`${dag}_${dagDeel}`">
            <UIInputCheckbox v-model="item[`${dag}_${dagDeel}`]" :label="dagDeel" />
          </span>
        </div>
      </template>

      <template #item-Selectie>
        <button class="btn purple" @click="() => selecteerAlles()">
          Alles selecteren
        </button>
      </template>

      <template #item-Aanvragen_via="{ item }">
        <UISelectFixed v-model="item.tabel_beschikbaarheden" :label="null" type="BESCHIKBAARHEID_TABELLEN" />
      </template>

      <template #item-Acties="{ item }">
        <button class="btn success" @click="() => save(item)">
          Opslaan
        </button>
      </template>
    </UITableResponsive>
  </div>
</template>

<script setup>
import { computed, inject, onMounted, ref, watch } from 'vue'

import UIInputCheckbox from '@/components/UI/Input/Checkbox'
import UISelectChauffeur from '@/components/UI/Select/Chauffeur.vue'
import UISelectFixed from '@/components/UI/Select/Fixed.vue'
import UITableResponsive from '@/components/UI/Table/Responsive.vue'

const dagen = ['MA', 'DI', 'WO', 'DO', 'VR', 'ZA', 'ZO']

const axios = inject('axios')
// const modal = inject('modal')
const page = ref(1)
const user_id = ref(null)
const werkdagen = ref({})

const refreshData = async () => {
  const params = { user_id: user_id.value, page: page.value }
  console.log(params)
  werkdagen.value = []
  if (!params.user_id) return
  const { data } = await axios.get('/api/dashboard/werkdagen/list', { params })
  werkdagen.value = data.werkdagen || { user_id: user_id.value }
}

const selecteerAlles = () => {
  dagen.forEach(dag => {
    werkdagen.value[`${dag}_VM`] = true
    werkdagen.value[`${dag}_NM`] = true
  })
}

onMounted(() => refreshData())
watch(user_id, (o, v) => {
  if (o !== v) refreshData()
})
const save = async item => {
  await axios.patch('/api/dashboard/werkdagen/save', { user_id: item.id, ...item })
}
const chauffeurTypes = ['master', 'admin', 'chauffeur']
const actieveChauffeursNietWerkend = computed(() => {
  return (dag, dagDeel) => {
    return werkdagen.value
      .filter(el => el.is_active && chauffeurTypes.includes(el.type) && !el[`${dag}_${dagDeel}`])
      .map(el => ({ ...el, fullname: [el.firstname, el.lastname].join(' ').trim() }))
      .sort((a, b) => a.fullname.localeCompare(b.fullname))
  }
})
</script>
